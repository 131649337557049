.modal.modal-dropdown {
  z-index: 3;
  opacity: 0.4;
}

.nk-header-app-logo {
  width: 120px !important;

  img {
    position: relative;
    top: -9px;
  }
}

.nk-header {
  .nk-header-tools {
    .user-avatar,
    [class^='user-avatar']:not([class*='-group']) {
      background: #526482;
    }

    .btn-primary {
      background-color: #6970c9;
      border-color: #6970c9;
      color: #fff;

      &:focus,
      &:hover {
        background-color: #5761c4;
        border-color: #5761c4;
      }
    }

    .btn-success {
      color: #fff;
      background-color: #35cd95;
      border-color: #35cd95;

      &:hover {
        background-color: #3cbe8e;
        border-color: #3cbe8e;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .nk-quick-nav-icon.d-none {
    display: block !important;
    position: fixed;
    left: 450px;
    top: 7px;
    height: 44px;
  }
}
@media only screen and (min-width: 1200px) {
  .nk-quick-nav-icon.d-none {
    display: none !important;
  }
}
