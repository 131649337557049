.templates-list {
  padding: 25px 0;

  .template {
    cursor: pointer;

    &:hover {
      opacity: 0.9;
      text-decoration: underline;

      .remove-template {
        opacity: 1;
      }
    }

    .remove-template {
      position: absolute;
      cursor: pointer;
      font-size: 20px;
      background-color: #fff;
      padding: 3px;
      border-radius: 100%;
      width: 21.4px;
      height: 21.4px;
      line-height: 0;
      right: 25px;
      top: 10px;
      opacity: 0;
      text-align: center;

      .icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        color: #6576ff;
      }
    }

    .template-preview {
      background-repeat: no-repeat;
      background-position: center;
      background-color: #000;
      border: 3px solid #000;
      border-radius: 3px;
      background-size: contain;
      height: 300px;
    }

    .template-name {
      padding: 10px;
      text-align: center;
      font-size: 15px;
      font-weight: 600;

      i {
        position: relative;
        top: 2px;
        margin-left: 5px;
      }
    }
  }
}
