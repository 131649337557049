.preloader {
  text-align: center;
  display: inline-block;
  width: 80px;
  margin: auto;
  padding: 25px 0;

  &.inline {
    padding: 0;
    margin: 0;
    width: auto;

    .spinner-border {
      width: 1rem;
      height: 1rem;
      margin: 0;
      position: relative;
      top: 2px;
    }
  }
}
