#tuls-modal,
#tuls-form,
.tuls-builder {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  transform: translateX(150px);
  z-index: 9;
  position: fixed;
  margin: auto;
  overflow: hidden;
  max-width: 700px;
}
#tuls-modal {
  background-color: #fff;
}

#tuls-modal.tuls-builder {
  position: fixed !important;
}
#tuls-modal.dropzone,
#tuls-modal .dropzone {
  padding: 0;
}
#tuls-modal .dropzone {
  background-color: transparent;
}

.slider__track {
  width: 100%;
  height: 7px;
  position: relative;
  z-index: 1;
  border-radius: 3px;
  display: block;
  margin: 20px 0;
  background-color: #dbdfea;
  z-index: 0 !important;
}

.slider__thumb {
  top: 0;
  width: 24px;
  height: 24px;
  background: #f5f5f5;
  border: 1px solid #ffffff;
  border-radius: 36px;
  -webkit-box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
}

.slider__thumb:focus {
  outline: none;
  border-color: #6576fe;
}

.slider__thumb:before,
.slider__thumb:after {
  content: '';
  display: block;
  position: absolute;
  height: 10px;
  width: 2px;
  background: #dbdfea;
  left: 10px;
  top: 6px;
}

.dropdown-menu-close {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.dropdown-menu {
  li {
    white-space: nowrap;
  }
}

.background-image {
  .custom-file-label,
  .custom-file-input {
    width: 81px;
    margin: auto;
  }

  .image {
    text-align: center;
    width: 50%;
    margin-right: 15px;

    img {
      width: 80%;
      margin: auto;
    }
  }

  .select-file {
    position: relative;
  }
}

.nk-sidebar.side-builder {
  height: 100%;
  border: none;
  background-color: transparent;
  position: absolute;
  z-index: 2;
  width: 320px;
  top: -18px;
  left: 0 !important;

  .nk-sidebar-menu {
    height: calc(100vh - 63px);

    .simplebar-wrapper {
      height: calc(100vh - 63px);

      #accordion {
        height: calc(100vh - 63px);
        border-radius: 0;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  .side-steps {
    width: 100%;
    margin-bottom: -25px;

    .btn {
      background-color: #eef0ff;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;

      &.active {
        border-bottom: 3px solid #6970c9;
      }

      &:disabled {
        opacity: 1;
      }
    }
  }

  .form-control-select:after {
    font-size: 25px;
    right: 5px;
  }

  .tuls-line {
    display: flex;
    padding: 3px;
    cursor: all-scroll;

    &:hover {
      box-shadow: 0 5px 10px #c7c7c7;
    }

    .tuls-column {
      background-color: #c7c7c7;
      padding: 20px;
      margin: 5px;

      &[data-width='1'] {
        width: 25%;
      }
      &[data-width='2'] {
        width: 50%;
      }
      &[data-width='3'] {
        width: 75%;
      }
      &[data-width='4'] {
        width: 100%;
      }
    }
  }

  .close-settings {
    cursor: pointer;
    font-size: 22px;

    &:hover {
      opacity: 0.8;
    }
  }

  .tuls-component {
    position: relative;
    width: 112px !important;
    height: 112px !important;
    text-indent: -1000px;
    overflow: hidden;
    border-radius: 5px;
    background-color: #c7c7c7 !important;
    background-image: none !important;
    display: inline-block;
    vertical-align: top;
    margin: 7px;
    cursor: move;
    outline: none !important;
    font-weight: 500 !important;
    font-size: 32px;
    font-family: 'Roboto', 'sans-serif';

    & > div {
      background-image: none !important;
    }

    &::before {
      font-family: 'Nioicon' !important;
      background-color: #f5f4f4 !important;
      color: #979797;
      width: 112px;
      height: 112px;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: 2;
      font-size: 32px;
      text-indent: 0;
      text-align: center;
      line-height: 86px;
    }

    &::after {
      width: 112px;
      display: block;
      color: #979797;
      position: absolute;
      bottom: 10px;
      left: 0;
      z-index: 2;
      font-size: 16px;
      text-indent: 0;
      text-align: center;
      font-weight: 500 !important;
      font-family: 'Roboto', 'sans-serif';
    }

    &[data-tuls-type='title'] {
      &::before {
        content: '';
      }
      &::after {
        content: 'title';
      }
    }
    &[data-tuls-type='text'] {
      &::before {
        content: '';
      }
      &::after {
        content: 'description';
      }
    }
    &[data-tuls-type='button'] {
      &::before {
        content: '';
      }
      &::after {
        content: 'button';
      }
    }
    &[data-tuls-type='backgroundImage'] {
      &::before {
        content: '';
      }
      &::after {
        content: 'image';
      }
    }
    &[data-tuls-type='codeFrame'] {
      &::before {
        content: '';
      }
      &::after {
        content: 'code frame';
      }
    }
    &[data-tuls-type='formFrame'] {
      &::before {
        content: '';
      }
      &::after {
        content: 'form';
      }
    }
  }

  .search-select {
    div [class$='-MenuList'] {
      div {
        display: block !important;
        text-indent: 0 !important;
        color: #000 !important;
        opacity: 1 !important;
        visibility: visible !important;
        position: relative !important;
        z-index: 2 !important;
      }
    }

    div [class$='-IndicatorsContainer'] {
      display: none !important;
    }
  }

  .layout {
    display: grid;
    grid-template-columns: 50% 50%;
    max-height: 450px;
    overflow: auto;

    label {
      width: 90%;
      padding: 5px;
      display: flex;
      position: relative;

      .image-wrap {
        display: flex;
        align-items: center;

        img {
          height: auto;
          margin-left: 5px;
        }
      }

      &:hover {
        .remove-template {
          opacity: 1;
        }
      }

      .remove-template {
        position: absolute;
        cursor: pointer;
        font-size: 16px;
        background-color: #fff;
        padding: 2px;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        line-height: 0;
        right: 3px;
        opacity: 0;

        .icon {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          color: #6576ff;
        }
      }
    }
  }

  .flex {
    display: flex;
  }

  .navigation {
    padding: 20px 25px;

    & > div {
      width: 50%;
    }

    .text-right {
      .btn {
        float: right;
      }
    }

    .btn {
      background-color: #6970c9;
      border-color: #6970c9;

      &:focus,
      &:active {
        background-color: #7077db;
        border-color: #7077db;
      }
    }

    .btn-save {
      background-color: #37cb95;
      border-color: #37cb95;
      color: #fff;

      &:focus,
      &:active,
      &:hover {
        background-color: #37bd8c;
        border-color: #37bd8c;
      }
    }
  }
}

.btn.btn-success {
  background-color: #37cb95;
  border-color: #37cb95;
  color: #fff;

  &:focus,
  &:active,
  &:hover {
    background-color: #37bd8c;
    border-color: #37bd8c;
  }
}

.btn.btn-primary {
  background-color: #6970c9;
  border-color: #6970c9;

  &:focus,
  &:active,
  &:hover {
    background-color: #7077db;
    border-color: #7077db;
  }
}

.btn.btn-danger {
  background-color: #ff5a46;
  border-color: #ff5a46;

  &:focus,
  &:active,
  &:hover {
    background-color: #eb6051;
    border-color: #eb6051;
  }
}

.mr-25 {
  margin-right: 25px;
}

#tuls-modal.tuls-builder {
  overflow: visible;

  &.capture {
    transform: none !important;
    margin: 0 !important;
    position: relative !important;

    .tuls-column {
      outline: none !important;
    }
  }

  .tuls-line {
    display: flex;
    margin: 1px 0;
    position: relative;

    &:hover {
      &::before,
      &::after {
        opacity: 1;
      }
    }

    &::before {
      opacity: 0;
      display: block;
      z-index: 5;
      content: '';
      font-size: 20px;
      font-family: 'Nioicon' !important;
      position: absolute;
      left: -30px;
      top: 0;
      cursor: pointer;
      padding: 10px;
    }

    &::after {
      opacity: 0;
      display: block;
      z-index: 5;
      content: '';
      font-size: 20px;
      font-family: 'Nioicon' !important;
      position: absolute;
      left: -30px;
      top: 20px;
      cursor: pointer;
      padding: 10px;
      font-weight: 100;
      font-size: 18px;
    }

    .tuls-column {
      min-height: 50px;
      outline: 1px dashed #b1b1b7;
      position: relative;
      overflow: hidden;

      &[data-width='1'] {
        width: 25%;
      }
      &[data-width='2'] {
        width: 50%;
      }
      &[data-width='3'] {
        width: 75%;
      }
      &[data-width='4'] {
        width: 100%;
      }

      .tuls-component {
        position: relative;

        &:hover {
          &::before,
          &::after {
            opacity: 1;
          }
        }

        &::before {
          opacity: 0;
          display: block;
          z-index: 5;
          content: '';
          font-size: 20px;
          font-family: 'Nioicon' !important;
          position: absolute;
          right: 15px;
          top: -15px;
          cursor: pointer;
          padding: 10px;
          font-weight: 100;
          font-size: 16px;
        }

        &::after {
          opacity: 0;
          display: block;
          z-index: 5;
          content: '';
          font-size: 20px;
          font-family: 'Nioicon' !important;
          position: absolute;
          right: 40px;
          top: -15px;
          cursor: pointer;
          padding: 10px;
          font-weight: 100;
          font-size: 16px;
        }

        &.tuls-code-frame {
          min-height: 60px;

          &::after {
            content: '\e9ee';
            font-family: 'Nioicon' !important;
            color: #979797;
            width: 100%;
            height: 120px;
            display: block;
            z-index: 2;
            font-size: 60px;
            text-indent: 0;
            text-align: center;
            line-height: 66px;
            opacity: 1;
            left: 0;
          }
        }

        &.tuls-form-frame {
          min-height: 60px;

          &::after {
            content: '';
            font-family: 'Nioicon' !important;
            color: #979797;
            width: 100%;
            height: 120px;
            display: block;
            z-index: 2;
            font-size: 60px;
            text-indent: 0;
            text-align: center;
            line-height: 66px;
            opacity: 1;
            left: 0;
          }
        }
      }

      &-empty {
        &::after {
          content: 'Drag something here from the panel';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          text-align: center;
          color: #526484;
          font-size: 12px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &.grid-glow {
    .tuls-grid-col {
      outline: 1px dashed #a6d5eb;
    }
  }

  .crop-template {
    position: absolute;
    bottom: -20px;
    right: -20px;
    font-size: 20px;
    cursor: all-scroll;
  }

  .setting-component {
    position: absolute;
    color: red;
    visibility: hidden;
    font-size: 20px;
    cursor: pointer;
    z-index: 999;
    background-color: #fff;
    padding: 2px;
  }

  .tuls-inner {
    height: 100%;

    .tuls-grid {
      display: grid;
      height: 100%;

      &:hover {
        .tuls-grid-col {
          outline: 1px dashed #a6d5eb;
        }
      }

      .tuls-grid-line {
        display: flex;
        height: 100%;

        .tuls-grid-col {
          width: 100%;
          position: relative;
        }
      }
    }
  }

  #crop-column {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 16px;
    cursor: all-scroll;
  }
}
