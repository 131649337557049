.badge {
  &.badge-gray {
    background-color: #526482;
    border-color: #526482;
  }

  &.badge-success {
    background-color: #35cd95;
    border-color: #35cd95;
  }

  &.badge-scheduled {
    background-color: #526482;
    border-color: #526482;
  }

  &.badge-created {
    background-color: #6970c9;
    border-color: #6970c9;
  }

  &.badge-error {
    background-color: #ff794d;
    border-color: #ff794d;
  }

  &.badge-sent {
    background-color: #35cd95;
    border-color: #35cd95;
  }
}
