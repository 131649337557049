.block-picker-wrapper {
  position: relative;
  z-index: 3;

  .block-picker {
    position: absolute !important;
    top: 10px;
    box-shadow: 0 0 4px 3px #f1f1f1 !important;
  }
}

.selected-color {
  border: 1px solid #dbdfea;
  cursor: pointer;
  display: flex;
  border-radius: 3px;
  padding: 8px;
  align-items: center;

  .color {
    width: 30px;
    height: 30px;
    border: 1px solid #dbdfea;
  }

  .color-name {
    padding: 2px 0 0 15px;
    color: #3c4d62;
  }
}
