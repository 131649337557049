.brand-logo {
  text-align: center;

  .logo-link {
    img {
      margin: auto;
      width: 200px;
    }
  }
}
