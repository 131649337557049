.dropdown.tools-dropdown {
  &.tools-builder {
    position: fixed;
    top: 100px;
    right: 10px;
    left: auto;
    z-index: 9;
  }

  .close-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .dropdown-dots {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
