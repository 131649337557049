.empty-data {
  margin: auto;
  text-align: center;

  .empty-image {
    background-image: url('../../assets/images/empty.png');
    background-size: cover;
    background-position: center center;
    height: 330px;
    max-width: 320px;
    margin-bottom: 38px;
    margin-top: 30px;
    margin: auto;
  }

  h5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 30px;
  }
}
