.used-limit {
  .title {
    font-weight: 500;
    margin-bottom: 10px;
  }

  .progress {
    margin-bottom: 10px;
    height: 35px;
    position: relative;

    .progress-bar {
      overflow: visible;
      background: #c8ceff;
      color: #5563d6;
      font-weight: 600;
      text-indent: -900px;

      &:after {
        content: attr(data-progress);
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        color: #5563d6;
        font-weight: 600;
        text-indent: 0;
        line-height: 35px;
      }
    }
  }
}
