.flowarea-create-list {
  position: fixed;
  top: 100px;
  right: 300px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
  border-radius: 0.25rem;
  padding: 10px;
  width: 300px;
  z-index: 5;

  .action {
    display: flex;
    cursor: pointer;

    .icon {
      width: 50px;
      font-size: 21px;
      text-align: center;
    }

    .name {
      padding: 5px 0;
      font-size: 16px;
    }
  }
}
