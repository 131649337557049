.typing-message {
  .dot {
    margin: 3px;
    width: 4px;
    height: 4px;
    background-color: #526484;
    border-radius: 100%;
    animation: slide-rotate-hor-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.5) 0s infinite alternate-reverse both;

    &:nth-child(2) {
      animation: slide-rotate-hor-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.5) 0.2s infinite alternate-reverse both;
    }

    &:nth-child(3) {
      animation: slide-rotate-hor-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.5) 0.4s infinite alternate-reverse both;
    }
  }
}

@keyframes slide-rotate-hor-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
