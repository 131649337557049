.sittings-page {
  display: flex;

  .side-menu {
    width: 300px;

    a {
      color: #6e82a5;
      font-size: 16px;

      &.disable {
        opacity: 0.5;
      }

      .icon {
        width: 18px;
        display: inline-block;
      }

      &.active {
        color: #7f8dff;
      }
    }

    .section-name {
      padding: 10px 0 15px 15px;
      font-weight: 500;

      & + ul {
        margin-bottom: 20px;
      }
    }

    .nav-item {
      .nav-item {
        .icon {
          padding-right: 10px;
          display: inline-block;
        }
      }
    }
  }

  .right-section {
    width: calc(100% - 300px);
    padding-top: 10px;

    .title {
      .icon {
        padding-right: 15px;
      }
    }

    .social-connect {
      text-align: center;
      margin: auto;
      max-width: 60%;
      padding: 3% 0;
      line-height: 35px;
    }

    .social-image {
      height: 150px;

      &--telegram {
        background: url('../../assets/images/social/telegram.png') center center no-repeat;
        background-size: contain;
      }
      &--sms {
        background: url('../../assets/images/social/sms.jpg') center center no-repeat;
        background-size: contain;
      }
      &--facebook {
        background: url('../../assets/images/social/facebook.png') center center no-repeat;
        background-size: contain;
      }
    }

    .connecting-form {
      max-width: 500px;
      margin: auto;
      font-size: 16px;

      .steps-list {
        margin: 20px 0;

        li {
          display: flex;
          margin: 20px 0;
          align-items: center;

          span {
            width: calc(100% - 60px);

            &.step {
              width: 40px;
              height: 40px;
              display: block;
              text-align: center;
              background-color: #f5f6fa;
              border-radius: 100%;
              font-size: 22px;
              line-height: 42px;
              margin-right: 20px;
            }
          }
        }
      }

      .btn-connect {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
  }

  .generate-token-icon {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

@media only screen and (max-width: 800px) {
  .sittings-page {
    .side-menu {
      width: 155px;
    }

    .right-section {
      width: calc(100% - 155px);
    }
  }
}

@media only screen and (max-width: 650px) {
  .sittings-page {
    display: block;

    .side-menu {
      width: 100%;

      ul {
        margin-bottom: 10px !important;

        li {
          display: inline-block;
        }
      }
    }

    .right-section {
      width: 100%;
    }
  }
}

.pull-right {
  float: right;
}
