.popup-list-table {
  min-width: 800px;

  tr {
    .stat-center {
      width: 12%;

      &-right {
        width: 4%;
      }
    }

    .stat-right {
      width: 6%;
      text-align: center;

      .custom-control {
        margin: auto;
        padding-left: 4.75rem;
      }
    }
  }
}

.stat-right.tb-tnx-item,
.stat-right.tb-tnx-action:last-child {
  border-bottom: none !important;
  display: table-cell !important;
}
