.channel-info {
  .channle-remove {
    font-size: 15px;

    .icon {
      font-size: 13px;
    }
  }

  .table {
    &.info {
      tr {
        td:first-child {
          font-weight: 700;
        }
      }
    }
  }
}
