.view-desktop,
.view-mobile {
  #tuls-modal {
    transform: translateX(0);
  }
}

.view-desktop .tuls-line .tuls-column,
.view-mobile .tuls-line .tuls-column {
  outline: none !important;
}

.view-desktop,
.view-mobile {
  .crop-template {
    display: none !important;
  }

  .tuls-line::before,
  .tuls-line::after,
  .tuls-component::before,
  .tuls-component::after {
    display: none !important;
    opacity: 0 !important;
  }
}

.view-desktop:before {
  content: '';
  display: block;
  background-image: url('../../assets/images/templates/browser-preview.png');
  background-repeat: no-repeat;
  position: fixed;
  top: 128px;
  height: 60px;
  width: calc(100% - 20px);
  background-size: contain;
  left: 10px;
  bottom: 0;
  height: calc(100% - 135px);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 5px #f1f1f1;
  border: 1px solid #dce0e8;
}

.view-desktop #tuls-modal {
  top: 200px !important;
}

.view-mobile #tuls-modal {
  max-width: 365px !important;
  max-height: 500px !important;
  top: 200px !important;
}

.view-mobile:after {
  content: '';
  display: block;
  background-image: url('../../assets/images/templates/mobile-preview.png');
  background-repeat: no-repeat;
  background-position: top center;
  position: fixed;
  top: 250px;
  height: 60px;
  width: 432px;
  background-size: inherit;
  bottom: 0;
  height: 100%;
  margin: auto;
  left: 0;
  right: 0;
}

.view-desktop {
  #tuls-modal.tuls-modal.tuls-modal {
    &--topLeft {
      top: 5% !important;
      left: 5% !important;
      bottom: auto !important;
      right: auto !important;
      transform: translateY(165px);
    }
    &--topCenter {
      top: 5% !important;
      right: 0 !important;
      bottom: auto !important;
      left: 0 !important;
      transform: translateY(165px);
    }
    &--topRight {
      top: 5% !important;
      right: 5% !important;
      bottom: auto !important;
      left: auto !important;
      transform: translateY(165px);
    }
    &--middleLeft {
      top: 0 !important;
      left: 5% !important;
      bottom: 0 !important;
      right: auto !important;
      transform: translateY(65px);
    }
    &--middleCenter {
      top: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      right: 0 !important;
      transform: translateY(65px);
    }
    &--middleRight {
      top: 0 !important;
      left: auto !important;
      bottom: 0 !important;
      right: 5% !important;
      transform: translateY(65px);
    }
    &--bottomLeft {
      top: auto !important;
      left: 5% !important;
      bottom: 5% !important;
      right: auto !important;
    }
    &--bottomCenter {
      top: auto !important;
      left: 0 !important;
      bottom: 5% !important;
      right: 0 !important;
    }
    &--bottomRight {
      bottom: 5% !important;
      right: 5% !important;
      top: auto !important;
      left: auto !important;
    }
  }
}
