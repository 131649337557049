.flowarea-sidebar {
  position: fixed;
  top: 75px;
  bottom: 10px;
  right: 0;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
  border-radius: 0.25rem;
  padding: 10px;
  width: 400px;
  z-index: 5;
  overflow: hidden;

  .lead-settings-body {
    overflow: auto;
    height: calc(100% - 72px);
    width: calc(100% - 10px);
    padding-bottom: 25px;
    padding-right: 10px;
    position: absolute;
  }

  .tools {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
    background-color: #fff;
    padding: 20px 0;
    margin: 0 -10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 -0.15rem 0.55rem rgb(0 0 0 / 10%);

    .copy-element,
    .remove-element {
      font-size: 19px;
      cursor: pointer;
      position: relative;
      top: 7px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .functions-list {
    span {
      background-color: #526482;
      padding: 4px 7px;
      border-radius: 3px;
      margin: 1px;
      color: #fff;
      display: inline-block;
      cursor: grab;
      font-size: 13px;
      font-weight: 300;
    }
  }

  .add-node-button {
    width: 100%;
    justify-content: center;

    .icon {
      margin-top: -2px;
      margin-right: 5px;
    }
  }

  .response-button,
  .card-item {
    padding: 15px;
    margin: 15px 0;
    border: 1px solid #f1f1f1;
    background-color: #fdfdfd;
  }

  .add-node-button {
    background-color: #6970c9;
    border-color: #6970c9;
    color: #fff;

    &:focus,
    &:hover {
      background-color: #5761c4;
      border-color: #5761c4;
    }
  }

  .btn-outline-primary {
    border-color: #6871ca;
    color: #6871ca;

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  .btn-outline-primary:hover {
    background-color: #5761c4;
    border-color: #5761c4;
  }

  .select-dropdown {
    .select-button {
      padding: 6px 15px;

      .icon {
        right: 5px;
        top: 4px;
      }
    }
  }

  .headers {
    .input-key {
      width: 30%;
      padding-right: 5px;
      margin-top: 5px;
    }

    .input-value {
      width: 60%;
      padding-right: 5px;
      margin-top: 5px;
    }

    .remove-header {
      width: 10%;
      text-align: center;
      margin-top: 5px;

      .icon {
        vertical-align: middle;
        margin-top: 11px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
