.close-buttons {
  display: flex;

  & > div {
    padding: 3px;
    text-align: center;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    svg {
      width: 50%;
    }
  }
}
