.chat-demo-modal {
  .modal-body {
    padding: 0;
  }
}

.chat-demo {
  min-height: 500px;
  margin: 0;
  padding: 0;

  .simplebar-content {
    padding: 20px;
    margin: 0;
    height: 500px;
    overflow: auto;
  }
}
