.billing-page {
  display: flex;

  .side-billing {
    width: 30%;

    .plan {
      text-align: center;

      .title {
        color: #6970c9;
        font-size: 18px;
      }

      .price {
        font-size: 38px;
        padding: 10px 0;
        font-weight: 600;

        sup {
          font-weight: 500;
          font-size: 20px;
        }

        sub {
          font-weight: 400;
          font-size: 17px;
        }
      }

      .btn-manage {
        margin: 10px auto;
      }
    }
  }

  .right-section {
    width: calc(70% - 1.5rem);
    margin-left: 1.5rem;

    .flex-limits {
      & > div:nth-child(2) {
        padding: 0 15px;
      }
    }
  }

  .plans-table {
    justify-content: center;
  }
}

@media only screen and (max-width: 750px) {
  .billing-page {
    display: block;

    .side-billing {
      margin: auto;
      width: 300px;
    }

    .right-section {
      width: 100%;
      margin: 40px auto;
    }
  }
}

@media only screen and (max-width: 450px) {
  .billing-page {
    display: block;

    .right-section {
      width: 100%;
      margin: 40px auto;

      .flex-limits {
        display: block;

        & > div {
          margin: 0;
          padding: 15px 0 !important;
          width: 100%;
        }
      }
    }
  }
}
