.select-dropdown {
  &.small-dropdown {
    height: 44px;

    .select-button {
      line-height: 18px;
      padding: 12px 25px;

      .icon {
        top: 10px;
      }
    }
  }
}
