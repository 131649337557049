.nk-chat {
  &.chat-wrapper {
    height: 100vh;
    max-height: 100vh;

    .chat-avatar {
      margin-bottom: 0;
      margin-right: 10px;
    }

    .chat-demo {
      min-height: auto;

      .simplebar-content {
        height: 94%;
        padding: 0 30px 40px 0;
        margin-right: -30px;
      }
    }

    .nk-chat-body {
      opacity: 1 !important;
      position: static !important;
      pointer-events: auto !important;

      .nk-chat-head-info {
        display: block !important;
      }

      .nk-chat-editor {
        position: fixed;
        bottom: 27px;
        left: 0;
        width: 100%;
      }

      .copyright {
        padding: 3px 0;
        background-color: #e1e6ed;
        font-size: 12px;
        border-top: 1px solid #d1d1d1;
      }
    }
  }
}
