.styled-table {
  .nk-tb-col-tools {
    width: 80px;
    max-width: 80px;
  }

  @media only screen and (max-width: 800px) {
    .td-creation_date {
      display: none !important;
    }
  }
}
