.form-list-table {
  tr {
    .stat-center {
      width: 12%;

      &-right {
        width: 4%;
      }
    }

    .stat-right {
      width: 6%;
      text-align: center;

      .custom-control {
        margin: auto;
        padding-left: 4.75rem;
      }
    }
  }
}
