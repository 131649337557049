.background-image {
  .flex {
    display: flex;
    align-items: center;
  }

  .custom-file-label,
  .custom-file-input {
    width: 81px;
    margin: auto;
  }

  .image {
    text-align: center;

    img {
      width: 35%;
      margin: auto;
    }
  }

  .select-file {
    position: relative;
  }
}
