.header-modal-preview {
  position: absolute;
  top: 65px;
  background-color: #fff;
  text-align: center;
  left: 0;
  right: 0;

  .inner {
    max-width: 300px;
    margin: auto;

    .item-button {
      cursor: pointer;
      padding: 15px 5px;

      &:hover {
        border-bottom: 3px solid #aabddc;
      }
      &.active {
        border-bottom: 3px solid #526482;
      }

      .fas {
        margin-right: 7px;
      }
    }
  }
}
