.tooltip-ui {
  position: relative;
  font-size: 15px;

  &:hover {
    .tooltip-ui-text {
      display: inline-block;
    }
  }

  &-text {
    position: absolute;
    top: -23px;
    padding: 5px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    transform: translateX(-55%);
    display: none;
  }
}
