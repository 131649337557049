.flex {
  display: flex;

  & > div {
    width: 50%;
  }
}

.pagination {
  display: flex;
  justify-content: center;

  li:nth-child(n + 20) {
    display: none;
  }
  li:nth-last-child(2),
  li:nth-last-child(3),
  li:nth-last-child(4) {
    display: list-item;
  }
}

.simplebar-wrapper .background-image .image div {
  background-repeat: no-repeat !important;
}
