.conversion-stat-graph {
  width: 100%;

  & .type-list {
    margin: 0;
    padding: 0;
    display: flex;
    margin-bottom: 15px;
    border-bottom: 1px solid #dbdfea;

    li {
      list-style-type: none;
      width: 100%;
      display: grid;
      border-right: 2px solid #dbdfea;
      padding: 15px;
      cursor: pointer;
      grid-template-columns: repeat(2, 1fr);
      align-items: end;

      &:hover,
      &.active {
        background-color: #efe8ff8c;
      }

      &:last-child {
        border-right: none;
      }

      .type-list {
        &_name {
          border-bottom: 1px #dbdfea dashed;
          width: 100%;
          grid-column-start: 1;
          grid-column-end: 4;
          grid-row-start: 1;
          grid-row-end: 3;
          margin-bottom: 10px;
          padding-bottom: 10px;
          font-size: 22px;
          font-weight: 400;
        }

        &_actual-count {
          font-size: 40px;
          font-weight: 500;
          width: 100%;
          text-align: center;
          line-height: 46px;
        }

        &_percent-dif {
          font-size: 18px;
          font-weight: 400;
          width: 100%;
        }
      }
    }
  }

  .card-inner {
    position: relative;
  }

  .period-wrap {
    display: flex;
    justify-content: end;
  }

  .period-select {
    position: relative;
  }
}

.highcharts-credits {
  display: none !important;
}
