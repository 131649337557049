.settings-web-chat {
  .nk-chat {
    &.chat-wrapper-settings {
      position: relative;
      margin: 0 auto;
      background-size: cover;
      z-index: 999;
      transition: all 0.1s ease-in-out 0s;
      width: 370px;
      height: 510px;
      max-height: 510px;
      min-height: 510px;
      background-color: transparent;
      background-image: none;
      box-shadow: rgb(162 162 162) 0px 0px 20px;
      border-radius: 10px;
      overflow: hidden;
      cursor: default;

      .nk-chat-editor {
        position: relative;
        margin-bottom: -20px;
      }

      .user-avatar {
        background-size: cover;
      }
    }
  }
}
