.versions-history-sidebar {
  position: fixed;
  top: 75px;
  bottom: 10px;
  right: 0;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
  border-radius: 0.25rem;
  padding: 20px;
  width: 400px;
  z-index: 5;
  overflow: auto;
  text-align: left;

  .close-sidebar {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px;
  }

  .version {
    padding: 20px;
    margin: 0 -20px;
    cursor: pointer;
    position: relative;

    &:hover,
    &.active {
      background-color: #6870c91c;
    }

    .name {
      font-weight: 500;
      max-width: 150px;
      overflow: hidden;
      display: inline-block;
      height: 23px;
      vertical-align: middle;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .edit {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 20px;
      color: #6970c9;
      opacity: 0;
    }

    &:hover {
      .edit {
        opacity: 1;
      }
    }
  }
}
