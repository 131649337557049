.select-dropdown {
  background-color: #fff;
  border: 1px #dbdfea solid;
  border-radius: 5px;
  width: 230px;
  z-index: 2;
  position: relative;

  .close-select {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .select-button {
    cursor: pointer;
    position: relative;
    font-weight: 500;
    line-height: 20px;
    padding: 15px 25px;

    &:hover {
      text-decoration: underline;
    }

    .icon {
      position: absolute;
      right: 15px;
      top: 12px;
      font-size: 25px;
    }
  }

  .dropdown-menu {
    width: 100%;
  }
}
