.position-selector {
  display: flex;

  .select {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin: auto;

    input {
      width: 20px;
      height: 20px;
      display: block;
      margin: 8px;
      cursor: pointer;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid #6576ff;
        background-color: #fff;
      }

      &:focus,
      &:active {
        outline: none;
      }

      &:checked {
        &::after {
          background-color: #6576ff;
        }
      }
    }
  }

  .description {
    padding-left: 10px;
  }
}
