.pagination {
  .page-link {
    &.active {
      z-index: 3;
      color: #fff;
      background-color: #6576ff;
      border-color: #6576ff;
    }
  }
}

.select-dropdown {
  &.small-dropdown {
    &.sort-contacts {
      height: 36px;
      width: 155px;

      .select-button {
        line-height: 16px;
        padding: 10px 16px;

        .icon {
          top: 6px;
          right: 8px;
          transform: scale(0.8);
        }
      }
    }
  }
}

.sort-dropdown {
  .btn-dim.btn-outline-primary {
    border-color: #dbdfea;
    color: #3c4d62;
    background-color: #ebeef2;

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  .btn-dim.btn-outline-primary:hover {
    background-color: #5761c4 !important;
    border-color: #5761c4 !important;
  }
}

.contacts-page {
  .lead-text {
    font-weight: 300;
  }
}
