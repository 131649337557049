.period-select {
  background-color: #fff;
  border: 1px #dbdfea solid;
  border-radius: 5px;
  width: 230px;
  z-index: 1;
  position: relative;
  height: 53px;

  .select-button {
    cursor: pointer;
    position: relative;
    font-weight: 500;
    line-height: 20px;
    padding: 15px 25px;

    &:hover {
      text-decoration: underline;
    }

    .icon {
      position: absolute;
      right: 15px;
      top: 12px;
      font-size: 25px;
    }
  }

  &.active {
    .select-button {
      .icon {
        transform: rotate(180deg);
      }
    }

    .period-list {
      display: block;
      position: relative;
      z-index: 2;
      background-color: #fff;
      width: 100%;
      box-shadow: 0 3px 12px 1px rgb(44 55 130 / 15%);
    }
  }

  .period-list {
    padding: 15px 25px;
    border-top: 1px #dbdfea solid;
    display: none;

    li {
      padding: 5px 0;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .period-range {
    position: absolute;
    right: 0;
    bottom: 0px;
    z-index: 2;

    .DateInput {
      width: 102px;

      &_input {
        font-size: 15px;
      }
    }

    .DateRangePickerInput__withBorder {
      border-radius: 0 0 5px 5px;
      border: none;
      border-top: 1px solid #dbdbdb;
    }
  }

  .DateRangePicker_picker {
    left: auto !important;
    right: 0 !important;
  }

  .close-select {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .spinner-border {
    max-height: 18px;
    max-width: 18px;
  }

  .preloader {
    margin-right: 15px;
  }
}
