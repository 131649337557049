.percent-graphs {
  display: flex;
  align-items: baseline;

  .percent-stat-graph {
    width: 100%;

    &:nth-child(2) {
      margin: 0 30px;
    }
  }
}

@media only screen and (max-width: 880px) {
  .percent-graphs {
    display: block;

    .percent-stat-graph {
      margin: 30px 0 !important;
    }
  }
}

.percent-stat-graph {
  &__item {
    margin-left: 50px;
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid #dbdfea;
    display: flex;
  }

  &__icon {
    margin: 2px 0 0 -35px;
    position: absolute;
    width: 22px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    text-align: center;

    img {
      max-width: 90%;
      max-height: 50%;
      margin: auto;
    }
  }

  &__name {
    width: calc(100% - 50px);
    position: relative;
    z-index: 1;
    font-weight: 400;
  }

  &__percent {
    width: 50px;
    text-align: center;
    height: 100%;
    z-index: 1;
    position: relative;
    font-weight: 500;

    &-line {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 1px;
      background-color: #efe8ff;
      display: block;
      z-index: 0;
    }
  }

  .more-link {
    cursor: pointer;
    margin-top: 5px;

    &:hover {
      text-decoration: underline;
    }
  }
}
