.flow-area-page {
  text-align: center;

  .flow-preview-header {
    position: fixed;
    top: 10px;
    left: 15px;
    z-index: 5;
    font-size: 17px;
    font-weight: 500;
  }

  .flow-preview {
    height: 100vh !important;
  }
}

.react-flow {
  &__minimap {
    height: auto;
  }

  .create-else {
    padding: 15px 0;

    &:after {
      content: '';
      display: block;
      width: 4px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #f5f6fa;
      position: absolute;
      left: 0px;
      right: 0;
      z-index: -1;
    }

    .react-flow__handle {
      margin-top: 10px !important;
      width: 5px !important;
      height: 5px !important;
      border-radius: 100% !important;

      &:last-child {
        bottom: 10px !important;
      }
    }

    .label {
      &:not([data-label='+']) {
        top: -21px !important;
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;

        &[data-label='false'] {
          color: #e85347;
        }
        &[data-label='true'] {
          color: #1ee0ac;
        }
      }
    }

    .inner {
      &:not([data-label='+']) {
        background-color: transparent;
        top: 20px;
      }
    }
  }

  .react-flow__node {
    &:not(.create-else) {
      top: -6px;

      .react-flow__handle-bottom {
        &:after {
          content: '';
          display: block;
          height: 5px;
          background-color: #f5f6fa;
          bottom: -5px;
          left: -1px;
          right: -1px;
          position: absolute;
        }
      }
    }

    &.selected {
      .inner {
        box-shadow: 0px 0px 8px 1px rgba(34, 60, 80, 0.2);
      }
    }
  }

  .conversation-end {
    & + .react-flow__node {
      display: none !important;
    }
  }

  .flow-button-node {
    cursor: pointer;
    width: 100px;
    text-align: center;

    .inner {
      border-radius: 20%/20%;
      width: 40px;
      height: 40px;
      background-color: #fff;
      text-align: center;
      display: grid;
      align-items: center;
      margin: 0 auto;
      position: relative;
      border: 2px solid #fff;
      font-size: 20px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
      }

      &::before {
        border-radius: 2%/30%;
        background-color: #fff;
        top: 6px;
        bottom: 6px;
        right: -2px;
        left: -2px;
        box-shadow: 0 0 0.75rem rgb(0 0 0 / 10%);
      }

      &::after {
        border-radius: 30%/2%;
        background-color: #fff;
        left: 6px;
        right: 6px;
        top: -2px;
        bottom: -2px;
        display: block;
        box-shadow: 0 0 0.75rem rgb(0 0 0 / 10%);
      }
    }

    .label {
      font-size: 7px;
      text-align: center;
      padding: 5px;
      background-color: #fff;
      margin: 4px auto;
      width: auto;
      display: inline-block;
      border-radius: 3px;
      box-shadow: 0 0 0.75rem rgb(0 0 0 / 10%);

      &[data-label='+'] {
        display: none;
      }
    }

    &.create-node {
      padding: 0 5px;
      display: none;
      width: 100px;

      .inner {
        background-color: transparent;
        border: 1px dashed #3c8efe;
        width: 30px;
        height: 30px;
      }
    }

    &.default-fallback-node {
      cursor: default;

      .inner {
        box-shadow: none !important;
      }
    }

    &.create-node-else {
      width: 100px;
      // height: 15px;

      .inner {
        background-color: #fff;
        border: 1px dashed #526484;
        width: 16px;
        height: 16px;
        line-height: 9px;
        font-size: 6px;
        padding: 0 2px;
        width: 15px;
        height: 15px;
      }

      .label {
        background-color: #fff;
        padding: 5px;
        width: 30px;
        margin: auto;
        position: relative;
        top: -45px;
        border-radius: 4px;
        font-weight: 600;
        border: 1px solid #3c8efe;
      }

      .react-flow__handle {
        width: 4px;
        height: 4px;

        &:last-child {
          bottom: -4px;
        }

        // &-bottom:before {
        //   content: '';
        //   display: block;
        //   height: 5px;
        //   background-color: #f5f6fa;
        //   bottom: -5px;
        //   left: -1px;
        //   right: -1px;
        //   position: absolute;
        //   z-index: -1;
        // }

        // &-top:after {
        //   content: '';
        //   display: block;
        //   height: 5px;
        //   background-color: #f5f6fa;
        //   top: -5px;
        //   left: -1px;
        //   right: -1px;
        //   position: absolute;
        //   z-index: -1;
        // }
      }
    }

    &.incoming-node {
      font-size: 8px;
      width: 100px;

      .inner {
        background-color: #37cb95;
        color: #fff;
      }
    }

    &.conversation-end {
      font-size: 8px;
      width: 100px;

      .inner {
        background-color: #37cb95;
        color: #fff;
      }
    }

    &.sms-node {
      font-size: 8px;
      width: 100px;

      .inner {
        background-color: #5660b9;
        color: #fff;
        border: 2px solid #fff;
      }
    }

    &.variable-node {
      font-size: 7px;
      width: 100px;

      .inner {
        background-color: #09c2de;
        color: #fff;
        border: 2px solid #fff;
      }
    }

    &.branch-node {
      font-size: 8px;
      height: 17px;
      width: 100px;

      .inner {
        background-color: #eea620;
      }
    }

    &.function-node {
      font-size: 8px;
      width: 100px;

      .inner {
        background-color: #eea620;
        color: #fff;
      }
    }

    &.response-node {
      font-size: 8px;
      width: 100px;

      .inner {
        background-color: #eea620;
        color: #fff;
      }
    }

    &.jump-node {
      font-size: 8px;
      width: 100px;

      .inner {
        background-color: #ffa9ce;
        color: #fff;
      }
    }

    &.http-request-node {
      font-size: 8px;
      width: 100px;

      .inner {
        background-color: #ffa9ce;
        color: #fff;
      }
    }

    .badge {
      position: absolute;
      top: 0;
      right: 18px;
      font-size: 6px;
      background-color: #ffffff;
      z-index: 2;
      color: #5660b9;
      border-color: #5660b9;
    }

    .label-stat {
      color: #fff;
      padding: 2px 3px;
      border-radius: 3px;
      margin-top: 4px;
      display: inline-block;
    }
  }
}

.flows-list {
  & > div {
    margin-bottom: 30px;
  }

  .card-title {
    .col-lg-9 {
      font-size: 16px;
      text-transform: none;
      letter-spacing: 0px;
      overflow-wrap: break-word;
      line-height: 1.3;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.react-flow {
  &__edge-path {
    stroke-width: 2px;
  }
}

.flow-preview {
  .create-else {
    height: 1px !important;
    padding: 0 !important;
    opacity: 0 !important;
  }
}
