/*!
 * Theme Name: Default
 * Package : DashLite
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
 * Version	 :	1.0.0
 * Updated	 :	07.23.2020
**/
@import './libs/fontawesome-icons.css';
body {
  letter-spacing: 0.3px;
}
