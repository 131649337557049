.pagination {
  .page-link {
    &.active {
      z-index: 3;
      color: #fff;
      background-color: #6576ff;
      border-color: #6576ff;
    }
  }
}

.highcharts-credits {
  display: none !important;
}

.graphs-wrap {
  .sms-graph-wrap {
    width: 70%;
  }

  .status-graph-wrap {
    width: 30%;
  }

  @media only screen and (max-width: 750px) {
    .sms-graph-wrap,
    .status-graph-wrap {
      display: none;
    }
  }

  .no-data-info {
    padding: 50px 0;
    text-align: center;
  }
}
